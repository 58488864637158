
.video-container{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 400px;
    height: 400px;
}

.video {
    /* Remove default browser styles */
    box-shadow: none;
    outline: none; /* Optional: Remove outline */
    width: 100%;
    height: 80%; /* Adjust the height to crop as desired */
    object-fit: cover; /* Ensures the video covers the entire container */
    overflow: hidden; /* Hides any overflow content */
    background-image: none; /* Removes the default background */
    border: 4px solid #005151;
    border-radius: 20px;
  }

  @media (max-width: 450px) {
    .video-container{
        width: 300px;
        height: 300px;
    }
  }

  @media (max-width: 350px) {
    .video-container{
        width: 200px;
        height: 200px;
    }
  }
