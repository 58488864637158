.NotFound{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
    flex-grow: 1;
    gap: 20px;
}

.NotFound-Title{
    color: #005151;
    font-size: 60px;
    font-family: Montserrat;
    font-style: normal;
    font-weight: 600;
    line-height: 100%;
    letter-spacing: -0.2px;
    text-align: center;
}

.NotFound-Subtitle{
    color: #005151;
    font-size: 25px;
    font-family: Montserrat;
    font-style: normal;
    font-weight: 400;
    line-height: 100%;
    letter-spacing: -0.2px;
    text-align: center;
}
