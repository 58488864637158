.RequestRow {
    display: flex;
    flex-direction: row;
    justify-content: left;
    align-items: center;
    width: 100%;
    min-width: 900px;
    font-size: 14px;
    font-weight: 500;
    border: 1px solid #E2E7ED;
    border-top: none;
    border-left: none;
    border-right: none;
    margin-right: 16px;
    height: 40px;
    min-height: 40px;
}

.RequestRowWrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    height: 100%;
}

.RequestRow:hover {
    cursor: pointer;
}

.Select {
    width: 14px;
    height: 14px;
}

.Select-Button {
    width: 14px;
    height: 14px;
    margin-left: 23px;
    outline: none;
    background: none;
    border: none;
}

.RequestName {
    min-width: 100px !important;
    width: 100px;
    margin-left: 34px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

.RequestTopic {
    min-width: 120px !important;
    width: 120px;
    margin-left: 60px;
    margin-right: 60px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

.RequestDOS {
    min-width: 120px !important;
    width: 120px;
    margin-left: 60px;
    margin-right: 5px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

.RequestStatus {
    min-width: 121px !important;
    width: 121px;
    height: 24px;
    margin-left: 60px;
    border-radius: 16px;
    background-color: #426B1F;
    color: #FFFFFF;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

.RequestSubject {
    display: flex;
    flex-direction: row;
    width: 0;
    flex: 1 1 0;
    margin-left: 60px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    color: #757775;
}

.RequestTime {
    display: flex;
    justify-content: flex-end;
    align-items: right;
    min-width: 60px !important;
    width: 60px;
    color: #202024;
    margin-right: 17px;
    margin-left: 60px;
    height: 20px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}
