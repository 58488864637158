.Request {
    display: flex;
    flex-direction: column;
    width: 100%;
    min-width: 1000px;
    height: 90.38vh;
    margin-right: 16px;
    margin-bottom: 16px;
    background-color: #FFFFFF;
    border-radius: 16px;
    margin-left: 12px;
    overflow: scroll;
}

.Request-Body {
    display: flex;
    flex-direction: column;
    height: 100%;
    margin-left: 36px;
    margin-right: 36px;
    max-width: 2550px;
}

.Request-Message {
    display: flex;
    flex-direction: column;
    margin-top: 12px;
    height: 30px;
    justify-content: center;
    align-items: center;
    border-bottom: 1px solid #454646;
    font-size: 14px;
    font-weight: 500;
    padding-bottom: 20px;
    margin-bottom: 36px;
}

.Request-SubTitle {
    display: flex;
    flex-direction: row;
    flex-grow: 1;
    margin-top: 28px;
    width: 100%;
}

.Request-Spacer {
    display: flex;
    flex-direction: row;
    flex-grow: 1;
}

.Request-Name {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    color: #202024;
    font-size: 14px;
    font-family: Montserrat;
    font-style: normal;
    font-weight: 500;
    letter-spacing: -0.14px;
    margin-right: 63px;
}

.Request-Topic {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    color: #202024;
    text-align: center;
    font-size: 14px;
    font-family: Montserrat;
    font-style: normal;
    font-weight: 500;
    letter-spacing: -0.14px;
    margin-right: 58px;
}

.Request-Status {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    color: #202024;
    text-align: center;
    font-size: 14px;
    font-family: Montserrat;
    font-style: normal;
    font-weight: 500;
    letter-spacing: -0.14px;
}

.Request-Time {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    color: #202024;
    text-align: right;
    font-size: 12px;
    font-family: Montserrat;
    font-style: normal;
    font-weight: 500;
    letter-spacing: -0.12px;
}
