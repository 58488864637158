.Sidebar{
    width: 256px;
}

.RequestButton{
    margin-left: 12px;
    width: 138px;
    height: 56px;
    outline: none;
    border: none;
    background-color: #9CC9C9;
    border-radius: 16px;
    display: flex;
    flex-direction: row;
    justify-content: left;
    align-items: center;
    margin-bottom: 8px;
}

.RequestButton:hover{
    box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 25%);
}

.RequestIcon{
    width: 19.93px;
    height: 19.93px;
    margin-left: 16px;
    margin-right: 16px;
}

.RequestTitle{
    font-family: 'Montserrat';
    font-size: 13px;
    font-weight:600;
    color: black;
}
