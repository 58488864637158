.RequestList {
    display: flex;
    flex-direction: column;
    width: 100%;
    /* max-width: 1200px; */
    /* Set a maximum width for the list */
    height: 90.38vh;
    margin-right: 16px;
    margin-bottom: 16px;
    background-color: #FFFFFF;
    border-radius: 16px;
    margin-left: 12px;
    overflow-y: scroll;
}

/* .RequestListRows {
    overflow-y: scroll;
} */
