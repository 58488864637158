.AccountInfo {
    display: flex;
    flex-direction: column;
    justify-content: top;
    align-items: center;
    width: 100%;
    height: 90.38vh;
    margin-right: 16px;
    margin-bottom: 16px;
    background-color: #FFFFFF;
    border-radius: 16px;
    margin-left: 12px;
    flex-grow: 1;
    position: relative;
}

.AccountInfo-Title {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: center;
    align-items: center;
    color: #000;
    text-align: center;
    font-size: 20px;
    font-family: Montserrat;
    font-style: normal;
    font-weight: 600;
    line-height: 100%;
    letter-spacing: -0.2px;
    margin-top: 20px;
    padding-bottom: 20px;
    border-bottom: 2px solid #F7F8FB;
}

.AccountForm {
    display: flex;
    flex-direction: column;
    justify-content: center;
    /* align-items: center; */
    width: 100%;
}

.AccountFormRow {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    height: 55px;
    gap: 20px;
    width: 100%;
}

.AccountFormSubtitle {
    align-items: left;
    width: 100%;
    color: #757775;
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: Montserrat;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 100%;
    letter-spacing: -0.14px;
    height: 46px;
}

.AccountFormTitle {
    display: flex;
    align-items: center;
    color: #000;
    text-align: center;
    font-size: 20px;
    font-family: Montserrat;
    font-weight: 600;
    line-height: 100%;
    letter-spacing: -0.2px;
    margin-bottom: 37px;
    min-height: 100px;
}

.AccountFormWrapper {
    display: flex;
    width: 100%;
    min-height: 400px;
    flex-direction: column;
    justify-content: top;
    align-items: center;
}

.AccountFormContent {
    display: flex;
    width: 100%;
    min-width: 670px;
    height: 100%;
    overflow-y: scroll;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    margin: 15px;
    padding-top: 20px;
}

.AccountFormContent-Header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 500px;
}

.AccountFormNav {
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    width: 100%;
    position: sticky;
    bottom: 0;
    justify-content: flex-start;
    background-color: white;
    z-index: 2;
    border-top: 2px solid #F7F8FB;
}

.AccountNextButton {
    width: 105px;
    height: 36px;
    border-radius: 4px;
    outline: none;
    border: none;
    background-color: #005151;
    color: white;
    font-size: 14px;
    font-weight: 600;
    margin-left: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
    margin-top: 20px;
    margin-right: 20px;
}

.AccountNextButton:hover {
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 25%);
    cursor: pointer;
}

.AccountInfo-Saved {
    position: fixed;
    top: 2%;
    left: 50%;
    width: 105px;
    height: 36px;
    border-radius: 4px;
    border: none;
    outline: none;
    background-color: #005151;
    color: white;
    font-size: 14px;
    font-weight: 600;
    font-family: Montserrat;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 25%);
    z-index: 1000;
    text-align: center;
}

.AccountFormCard {
    margin-top: 20px;
    margin-bottom: 20px;
    border-radius: 4px;
    border: 1px solid #005151;
    padding: 20px;
    padding-top: 40px;
}
