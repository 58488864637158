.RequestTable-Title {
    color: #757775;
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: Montserrat;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 200%;
    letter-spacing: -0.14px;
    border-bottom: 1px solid #E2E7ED;
    margin-top: 18px;
}

.table {
    width: 100%;
    border-spacing: 0 10px;
}

.table th,
.table td {
    padding: 1px;
    text-align: left;

    color: #757775;
}

.table th {
    background-color: white;
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: Montserrat;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 100%;
    letter-spacing: -0.14px;
}

.table .left-column {
    color: #757775;
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: Montserrat;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 200%;
    letter-spacing: -0.14px;
}

.true-cell {
    width: 14px;
    height: 14px;
    flex-shrink: 0;
    border: 1.5px solid #C3C3C3;
    background: #426B1F;
}

.false-cell {
    width: 14px;
    height: 14px;
    flex-shrink: 0;
    border: 1.5px solid #C3C3C3;
    background: none;
}

.line {
    border-bottom: 1px solid #E2E7ED;
}

.centered {
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
}
