.AccountSideBar{
    display: flex;
    flex-direction: column;
    width: 256px;
    flex-grow: 1;
    gap: 8px;
    margin-top: 20px;
}

.AccountSideBar-Option-Selected{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    border-radius: 0px 16px 16px 0px;
    background: #DAEBEB;
    width: 244px;
    height: 32px;
}

.AccountSideBar-Option-Unselected{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    border-radius: 0px 16px 16px 0px;
    background: none;
    width: 244px;
    height: 32px;
}

.AccountSideBar-Option-Unselected:hover{
    cursor: pointer;
    box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 25%);
}

.AccountSideBar-Option-Text{
    color: #000;
    font-size: 13px;
    font-family: Montserrat;
    font-style: normal;
    font-weight: 600;
    line-height: 100%;
    letter-spacing: -0.13px;
    margin-left: 31px;
}
