.TestimonialCard{
    display: flex;
    flex-direction: column;
    justify-content: top;
    align-items: center;
    margin-bottom: 60px;
    width: min-content;
}

.TestimonialCard:hover {
    cursor: pointer;
}

.TestimonialCard-Image{
    width: 100px;
    height: 100px;
    flex-shrink: 0;
    border-radius: 100px;
    border: 5px solid #005151;
    transform: translateY(50px); /* Translate down by 88px */
    z-index: 2; /* Ensure it's above the box */
}

.TestimonialCard-Box{
    display: flex;
    flex-direction: column;
    justify-content: left;
    align-items: left;
    width: 300px;
    height: 300px;
    flex-shrink: 0;
    border-radius: 5px;
    border: 5px solid #005151;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    padding: 40px;
}

.TestimonialCard-QuoteMark{
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex-shrink: 0;
    color: #005151;
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: Montserrat;
    font-size: 25px;
    font-style: normal;
    font-weight: 800;
    line-height: 141%; /* 56.4px */
    letter-spacing: 0px;
}

.TestimonialCard-Quote{
    color: #005151;
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: Montserrat;
    font-size: 25px;
    font-style: normal;
    font-weight: 500;
    line-height: 141%; /* 56.4px */
    letter-spacing: 0px;;
}

.TestimonialCard-Title{
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex-shrink: 0;
    color: #005151;
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: Montserrat;
    font-size: 15px;
    font-style: normal;
    font-weight: 600;
    line-height: 100%; /* 25px */
    letter-spacing: -1.5px;
}

@media (max-width: 1000px) {
    .TestimonialCard{
        padding: 20px;
    }
    .TestimonialCard-Box {
        width: 300px;
        height: 300px;
        padding: 40px;
    }
    .TestimonialCard-Image{
        width: 100px;
        height: 100px;
        transform: translateY(50px); /* Translate down by 50px */
    }
    .TestimonialCard-Quote{
        font-size: 20px;
        letter-spacing: 0px;
    }
    .TestimonialCard-QuoteMark{
        font-size: 20px;
    }
    .TestimonialCard-Title{
        font-size: 15px;
        letter-spacing: 0px;
    }
}

@media (max-width: 700px) {
    .TestimonialCard-Box {
        width: 200px;
        height: 300px;
        padding: 40px;
    }
}
