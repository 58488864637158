.Security {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.SecurityIcon {
  width: 112px;
  height: 112px;
  margin-bottom: 32px;
  margin-left: 32px;
  display: inline-block;
}

.Title {
  margin-bottom: 44px;
  font-size: 60px;
  color: #005151;
}

.SecuritySubtitle {
  font-size: 25px;
  width: 79vw;
  color: #313132;
  text-align: center;
  margin-bottom: 44px;
  font-weight: 600;
}

.LearnMoreButton {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 260px;
  border: 1px solid #005151;
  color: #005151;
  font-size: 14px;
  width: 144px;
  height: 52px;
  border-radius: 26px;
  font-weight: 600;
}

.LearnMoreButton:hover {
  background-color: #005151;
  color: white;
}

@media (max-width: 1000px) {
  .Title {
    font-size: 35px;
  }

  .SecuritySubtitle {
    font-size: 20px;
  }
}
