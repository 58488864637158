body {
    background-color: white;
    font-family: 'Montserrat';
}

.ParseTranscriptSplitScreen {
    display: flex;
    flex-direction: row-reverse;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
}

.ParseTranscriptOuterFrame {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: 100vh;
    overflow-y: scroll;
}

.ParseTranscriptFrame {
    display: flex;
    justify-content: center;
    align-items: center;
    width: min-content;
    height: 647px;
    flex-shrink: 0;
    border-radius: 16px;
    border: 1px solid #DADCE0;
    background: #FFF;
    overflow-y: scroll;
    overflow-x: scroll;
}

.ParseTranscript {
    display: flex;
    flex-direction: column;
    justify-content: top;
    align-items: center;
    width: 100%;
}

.ParseTranscriptRow {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    height: 55px;
    gap: 20px;
    width: 100%;
}

.ParseTranscriptSubtitle {
    align-items: left;
    width: 100%;
    color: #757775;
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: Montserrat;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 100%;
    letter-spacing: -0.14px;
    margin-bottom: 24px;
}

.ParseTranscriptTitle {
    display: flex;
    align-items: center;
    color: #000;
    text-align: center;
    font-size: 20px;
    font-family: Montserrat;
    font-weight: 600;
    line-height: 100%;
    letter-spacing: -0.2px;
    margin-bottom: 37px;
}

.ParseTranscriptWrapper {
    display: flex;
    width: 670px;
    height: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.ParseTranscriptContent {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    height: 391px;
    margin-left: 27px;
    margin-right: 27px;
}

.ParseTranscriptNav {
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    width: 100%;
}

.ParseTranscriptNextButton {
    width: 105px;
    height: 36px;
    border-radius: 4px;
    outline: none;
    border: none;
    background-color: #005151;
    color: white;
    font-size: 14px;
    font-weight: 600;
    margin-left: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 37px;
    margin-top: 37px;
}

.ParseTranscriptNextButton:hover {
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 25%);
    cursor: pointer;
}

.ParseTranscriptLeftArrow {
    width: 32px;
    margin-left: 0px;
}

.ParseTranscriptLeftArrow:hover {
    cursor: pointer;
}

.ParseTranscriptTextBox {
    height: 400px;
}
