.Status {
    border-radius: 16px;
    width: 121px;
    height: 24px;
    flex-shrink: 0;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    color: white;
    font-weight: 600;
}

.Unknown {
    background: #9A9A9A;
}

.Calling {
    background: #C9A731;
}

.Scheduled {
    background: #1F95D3;
}

.Done {
    background: #426B1F;
}

.Incomplete {
    background: #933414;
}

.Invalid {
    background: #933414;
}

.Cancelled {
    background: #9A9A9A;
}
