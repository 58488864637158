.MVPHeader {
    height: 64px;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: left;
    align-items: center;
}

.MVPHeaderLeft {
    width: 256px;
    display: flex;
    flex-direction: row;
    justify-content: left;
    align-items: center;
}

.MVPHeader-Logo {
    width: 48px;
    height: 48px;
    margin-left: 40px;
    border-radius: 50%;
}

.MVPHeader-Logo:hover {
    cursor: pointer;
    box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 25%);
}

.MVPHeader-Logo-Text {
    margin-left: 20px;
    color: #005151;
    font-size: 20px;
    font-family: Montserrat;
    font-style: normal;
    font-weight: 600;
    line-height: 100%;
    letter-spacing: -0.11px;
}

.HeaderSpacer {
    display: flex;
    flex-direction: row;
    flex-grow: 1;
}

.MVPSearchBar {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 48px;
    justify-content: left;
    align-items: center;
    background-color: #EBF1FA;
    border-radius: 48px;
}

.Search-Icon {
    width: 18.15px;
    height: 17.05px;
    padding-left: 16px;
    padding-right: 13px;
}

.HeaderInput {
    border: none;
    outline: none;
    background-color: #EBF1FA;
    display: flex;
    height: 30px;
    width: 100%;
    margin-right: 30px;
    font-family: 'Montserrat';
    font-size: 16;
    font-weight: 400;
}

.MVPHeaderRight {
    width: 179px;
    display: flex;
    flex-direction: row;
    justify-content: right;
    align-items: center;
}

.Avatar {
    border: 1px solid #DBDCDF;
    outline: 0;
    border-radius: 8px;
    width: 134px;
    height: 48px;
    background-color: #FFFFFF;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-right: 16px;
    margin-left: 29px;
}

.Avatar:hover {
    cursor: pointer;
    box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 25%);
}

.Avatar-Text {
    color: #005151;
    font-size: 10px;
    font-family: Montserrat;
    font-style: normal;
    font-weight: 700;
    line-height: 100%;
    letter-spacing: -0.11px;
}

.Avatar-Icon {
    width: 32px;
    height: 32px;
    margin-right: 5px;
    margin-left: 6px;
    border-radius: 32px;
}

.RequestsNav {
    color: #005151;
    font-size: 13px;
    font-family: Montserrat;
    font-style: normal;
    font-weight: 600;
    line-height: 100%;
    letter-spacing: -0.13px;
}

.RequestsNav:hover {
    cursor: pointer;
}
