.SidebarOption {
    width: 232px;
    height: 32px;
    border-radius: 16px;
    display: flex;
    flex-direction: row;
    justify-content: left;
    align-items: center;
    margin-left: 12px;
    transition: background-color 0.3s ease;
    /* Smooth transition for background color */
}

.SidebarOption:hover {
    background-color: rgba(157, 157, 157, 0.2);
    cursor: pointer;
    /* Light shadow of the background color on hover */
}

.SidebarOption.active {
    background-color: #B4E3E3;
    /* Highlighted background color when active */
}

.SidebarIcon {
    width: 14px;
    height: 14px;
    margin-left: 19px;
    margin-right: 19px;
}

.SidebarTitle {
    font-family: 'Montserrat';
    font-size: 13px;
    font-weight: 600;
    width: 100%;
    flex-grow: 1;
}

.SidebarNumber {
    display: flex;
    flex-direction: row;
    justify-content: right;
    font-family: 'Montserrat';
    font-size: 13px;
    font-weight: 600;
    width: 100%;
    margin-right: 14px;
}
