.HowItWorks {
    display: flex;
    flex-direction: column;
    margin-bottom: 237px;
    align-items: center;
}

.Title {
    margin-bottom: 76px;
    font-size: 60px;
    color: #005151;
}

.Steps {
    display: flex;
    flex-direction: column;
    background-image: url("./Assets/StepBackgroundImageLGCenter.svg");
    background-repeat: no-repeat;
    background-position-x: center;
    background-position-y: top;
    background-size: 200px;
}

.Step {
    display: flex;
    flex-direction: row;
    width: 100%;
    margin-bottom: 143px;
}

.Left {
    display: flex;
    flex-direction: column;
    flex-basis: 100%;
    flex: 1;
    text-align: right;
    align-items: flex-end;
    margin-right: 52px;
    max-width: 500px;
}

.Right {
    display: flex;
    flex-direction: column;
    flex-basis: 100%;
    flex: 1;
    margin-left: 52px;
    max-width: 500px;
}

.Number {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #005151;
    width: 39px;
    height: 39px;
    border-radius: 39px;
    color: white;
    margin-bottom: 29px;
    font-size: 20px;
    font-weight: 500;
}

.Subtitle {
    color: #005151;
    font-size: 25px;
    font-weight: 500;
    margin-bottom: 23px;
}

.Subtext {
    font-size: 25px;
    font-weight: 400;
    color: #313132;
}

.StepImage {
    width: 40vw;
    /* height: 347px; */
    z-index: 1;
}

.ImageSM {
    visibility: hidden;
    height: 0px;
    width: 0px;
}

@media (max-width: 1000px) {
    .Title{
        font-size: 35px;
    }

    .Steps {
        background-image: none;
        align-items: start;
    }

    .Step {
        flex-direction: column;
        align-items: start;
        max-width: 100vw;
        margin-bottom: 44px;
    }

    .Left {
        text-align: left;
        align-items: start;
        margin-left: 0px;
        margin-right: 0px;
        max-width: 90vw;
    }

    .Right {
        margin-left: 0px;
        margin-right: 0px;
        max-width: 90vw;
    }

    .ImageSM {
        display: flex;
        justify-content: center;
        align-items: center;
        visibility: visible;
        height: min-content;
        width: 90vw;
        margin-bottom: 143px;
    }

    .StepImage {
        height: 0px;
        width: 0px;
    }
}
