.JsonToTableWrapper {
    display: flex;
    margin-top: 24px;
    margin-left: 24px;
}

.JsonToTable-Input {
    min-width: 400px;
    min-height: 48px;
}

.JsonToTable-Button {
    margin-top: 48px;
    margin-left: 8px;
}
