.OnboardingForm {
    display: flex;
    flex-direction: column;
    justify-content: top;
    align-items: center;
    width: 100%;
}

.OnboardingFormRow {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    height: 55px;
    gap: 20px;
    width: 100%;
}

.OnboardingFormSubtitle {
    align-items: left;
    width: 100%;
    color: #757775;
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: Montserrat;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 100%;
    letter-spacing: -0.14px;
    margin-bottom: 10px;
}

.OnboardingFormTitle {
    display: flex;
    align-items: center;
    color: #000;
    text-align: center;
    font-size: 20px;
    font-family: Montserrat;
    font-weight: 600;
    line-height: 100%;
    letter-spacing: -0.2px;
    margin-bottom: 37px;
}

.OnboardingFormWrapper {
    display: flex;
    width: 670px;
    height: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.OnboardingFormContent {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    height: 391px;
}

.OnboardingFormNav {
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    margin-top: 37px;
    width: 100%;
}

.OnboardingNextButton {
    width: 105px;
    height: 36px;
    border-radius: 4px;
    outline: none;
    border: none;
    background-color: #005151;
    color: white;
    font-size: 14px;
    font-weight: 600;
    margin-left: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.OnboardingNextButton:hover {
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 25%);
    cursor: pointer;
}

.OnboardingLeftArrow {
    width: 32px;
    margin-left: 0px;
}

.OnboardingLeftArrow:hover {
    cursor: pointer;
}
