.RequestFooter {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 48px;
    font-size: 14px;
    font-weight: 500;
    /* border:1px solid #E2E7ED; */
    border-bottom: none;
    border-left: none;
    border-right: none;
}

.Resubmit {
    margin-left: 33px;
    width: 133px;
    height: 32px;
    outline: none;
    border: 1px solid #747775;
    background-color: white;
    border-radius: 18.5px;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    color: #747775;
    /* margin-bottom: 8px; */
}
