.ProfileModal{
    z-index: 1;
    width: 376px;
    height: 227px;
    position: fixed;
    border-radius: 28px;
    background: #F3F6FB;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    right: 16px;
    top: 64px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.ProfileModalAvatar{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 360px;
    height: 145px;
    flex-shrink: 0;
    border-radius: 24px;
    background: #FEFEFE;
    margin-top: 32px;
}

.ProfileModal-AvatarIcon{
    width: 65px;
    height: 65px;
    margin-left: 15px;
    border-radius: 65px;
}

.ProfileModalProfileInfo{
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 360px;
    padding-top: 15px;
    padding-left: 15px;
    gap: 14px;
    margin-bottom: 9px;
}

.ProfileModal-Text{
    display: flex;
    flex-direction: column;
    gap: 7px;
}

.ProfileModal-Name{
    color: #454846;
    font-size: 14px;
    font-family: Montserrat;
    font-style: normal;
    font-weight: 500;
    line-height: 100%;
    letter-spacing: -0.14px;
}

.ProfileModal-Email{
    color: #454846;
    font-size: 11px;
    font-family: Montserrat;
    font-style: normal;
    font-weight: 500;
    line-height: 100%;
    letter-spacing: -0.11px;
}

.ProfileModalAccountButton{
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
    border: 1px solid #767875;
    width: 176px;
    height: 32px;
    color: #767875;
    font-size: 13px;
    font-family: Montserrat;
    font-style: normal;
    font-weight: 600;
    line-height: 100%;
    letter-spacing: -0.13px;
    margin-bottom: 24px;
}

.ProfileModalAccountButton:hover{
    cursor: pointer;
    color: white;
    background-color: #767875;
}

.ProfileModalSignout{
    display: flex;
    flex-direction: row;
    width: 360px;
    justify-content: left;
    align-items: left;
    height: 24px;
    margin-left: 8px;
    margin-right: 8px;
    margin-top: 13px;
    margin-bottom: 13px;
    gap: 37px;
}

.Signout{
    margin-left: 38px;
}

.ProfileModalSignout:hover{
    background-color: #C8E7FD;
    cursor: pointer;
}
