.SiteHeader {
    display: flex;
    flex-direction: row;
    height: 97px;
    align-items: center;
    justify-content: space-between;
    padding-left: 6vw;
    padding-right: 6vw;
    background-color: white;
    position: sticky; /* Change this from absolute to sticky */
    top: 0; /* This will make it stick to the top */
    z-index: 100;
    flex-grow: 1;
}


.SiteHeader-LeftWrapper{
    height: 97px;
    display: flex;
    flex-direction: row;
    justify-content: left;
    align-items: center;
    flex-grow: 1;
}


.SiteHeader-Logo-Icon{
    width: 21px;
    height: 21px;
    flex-shrink: 0;
}


.SiteHeader-Logo-Text{
    display: flex;
    width: 293px;
    height: 29px;
    flex-direction: column;
    justify-content: center;
    flex-shrink: 0;
    color: #0E1019;
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: Montserrat;
    font-size: 30px;
    font-style: normal;
    font-weight: 600;
    line-height: 100%; /* 30px */
    letter-spacing: -0.3px;
    margin-left: 9px;
}


.SiteHeader-RightWrapper {
    height: 97px;
    display: flex;
    flex-direction: row;
    justify-content: right;
    align-items: center;
    flex-grow: 1;
}


.SiteHeader-LoginButton{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 135px;
    height: 39px;
    flex-shrink: 0;
    border-radius: 77px;
    border: 2px solid #377C6F;
    background: #FFF;
    color: #377C6F;
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: Montserrat;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 100%; /* 20px */
    letter-spacing: -1.2px;
    text-decoration: none;
    margin-right: 37px;
}


.SiteHeader-LoginButton:hover {
    cursor: pointer;
    background-color: #005151;
    color: white;
}


.SiteHeader-DemoButton{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 160px;
    height: 39px;
    flex-shrink: 0;
    border-radius: 77px;
    background: #377C6F;
    color: #F3F7F0;
    text-align: center;
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: Montserrat;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 100%; /* 20px */
    letter-spacing: -1.2px;
}

.SiteHeader-DemoButton:hover {
    cursor: pointer;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 25%);
}


/* CSS for mobile screens */
@media (max-width: 1000px) {
    .SiteHeader-Logo-Text{
        height: 29px;
        width: 45px;
        font-size: 12px;
    }


    .SiteHeader-LoginButton{
        width: 79px;
        height: 27px;
        font-size: 13px;
        margin-right: 13px;
    }


    .SiteHeader-DemoButton{
        width: 108px;
        height: 27px;
        font-size: 13px;
    }
}
