.RequestListHeader {
    display: flex;
    flex-direction: row;
    justify-content: left;
    align-items: center;
    width: 100%;
    min-width: 900px;
    height: 48px;
    border: 1px solid #E2E7ED;
    border-top: none;
    border-left: none;
    border-right: none;
}

.Select {
    width: 14px;
    height: 14px;
    margin-left: 23px;
}

.Carrot {
    width: 8px;
    height: 5px;
    margin-left: 9px;
}

.Reload {
    width: 12px;
    height: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.Reload-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    width: 28px;
    height: 28px;
    margin-left: 30px;
    border-radius: 14px;
}

.Reload-wrapper:hover {
    background-color: #F7F8FB;
}

.Dots {
    width: 3.27px;
    height: 12px;
    margin-left: 32px;
}

.Count {
    display: flex;
    flex-direction: row;
    justify-content: right;
    width: 100%;
    margin-right: 16px;
    font-family: 'Montserrat';
    font-size: 12;
    font-weight: 400;
    color: #5F5E5E;
    height: 48px;
    align-items: center;
}
