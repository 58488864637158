
.SiteFooter{
    display: flex;
    flex-direction: column;
    width: 100%;
}

.SiteFooterMain{
    display: flex;
    flex-direction: row;
    justify-content: right;
    align-items: center;
    padding-top: 44px;
    padding-bottom: 44px;
    width: 100%;
    background-color: #005151;
}

.SiteFooterSub{
    display: flex;
    flex-direction: row;
    justify-content: right;
    align-items: center;
    padding-top: 44px;
    padding-bottom: 44px;
    width: 100%;
    background-color: white;
}

.FooterLink{
    margin-right: 44px;
    cursor: pointer;
    color: #F3F7F0;
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: Montserrat;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 100%; /* 20px */
    letter-spacing: -1.2px;
}

.FooterLogo{
    display: flex;
    flex-direction: row;
    font-size: 40px;
    font-weight: 600;
    color: white;
    width: fit-content;
    margin-left: 44px;
    align-items: center;
    flex-grow: 1;
}

/* CSS for mobile screens */
@media (max-width: 1000px) {
    .FooterLogo {
      font-size: 20px;
      min-width: 150px;
      flex-grow: 1;
    }
    .SiteFooterSub{
        justify-content: center;
        gap: 10vw;
    }
  }
