/* Container for the Testimonials Section */
.Testimonials {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 20px; /* Adjust as needed */
    box-sizing: border-box;
}

/* Swiper Container */
.testimonialSwiper {
    width: 80vw; /* Adjust to desired width */
    max-width: 1200px; /* Adjust as needed */
    margin: 0 auto;
    padding: 20px; /* Adjust as needed */
    box-sizing: border-box;
    position: relative; /* Needed for positioning navigation buttons */
    padding-bottom: 40px; /* Adjust padding to accommodate the arrows */
}

/* Swiper Pagination */
.swiper-pagination-bullet-active {
    background-color: #005151;
}

/* Individual Swiper Slide */
.swiper-slide {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
}

/* Testimonial Card */
.TestimonialCard {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: left;
    text-align: left;
    padding: 20px; /* Adjust as needed */
    box-sizing: border-box;
}

/* Navigation Arrows */
.swiper-button-next,
.swiper-button-prev {
    color: #005151;
    position: absolute;
    top: 100%; /* Position below the Swiper container */
    /* margin-top: 10px; 10px below the Testimonial Card */
    transform: translateY(-100%);
    width: auto; /* Ensure full width of the arrows */
    height: 15px; /* Set height to 15px */
}

/* Position the navigation buttons next to each other */
.swiper-button-prev {
    left: 45%; /* Fixed distance from the left */
}

.swiper-button-next {
    right: 45%; /* Fixed distance from the prev button */
}

@media (max-width: 1000px) {
    .swiper-button-prev {
        left: 30%; /* Fixed distance from the left */
    }
    .swiper-button-next {
        right: 30%; /* Fixed distance from the prev button */
    }
}
