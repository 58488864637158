.NewOnboardModal {
    z-index: 1;
    width: 760px;
    height: 560px;
    background-color: #FFFFFF;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    box-shadow: 0px 4px 23px 8px rgba(0, 0, 0, 25%);
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.NewOnboardModal-Header {
    display: flex;
    flex-direction: row;
    justify-content: left;
    align-items: center;
    height: 40px;
    width: 100%;
    background-color: #F4F6FB;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
}

.NewOnboardModal-HeaderTitle {
    font-family: 'Montserrat';
    font-size: 13px;
    font-weight: 600;
    display: flex;
    align-items: center;
    width: 100%;
    margin-left: 17px;
    color: #0B1E45;
    height: 40px;
}

.NewOnboardModal-FormWrapper {
    display: flex;
    width: 670px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 37px;
}

.NewOnboardModal-Form {
    display: flex;
    flex-direction: column;
    justify-content: top;
    align-items: center;
    width: 100%;
    height: 100%;
}

.NewOnboardModal-FormRow {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    height: 55px;
    gap: 20px;
    width: 100%;
}

.NewOnboardModal-Loading {
    align-items: center;
    width: 100%;
    color: #757775;
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: Montserrat;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 100%;
    letter-spacing: -0.14px;
    margin-bottom: 10px;
}

.NewOnboardModal-FormSubtitle {
    align-items: left;
    width: 100%;
    color: #757775;
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: Montserrat;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 100%;
    letter-spacing: -0.14px;
    margin-bottom: 10px;
}

.NewOnboardModal-FormTitle {
    display: flex;
    align-items: center;
    color: #000;
    text-align: center;
    font-size: 20px;
    font-family: Montserrat;
    font-weight: 600;
    line-height: 100%;
    letter-spacing: -0.2px;
    height: 50px;
    margin-bottom: 20px;
}

.NewOnboardModal-FormContent {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    height: 391px;
}

.NewOnboardModal-FormNav {
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    margin-top: 20px;
    width: 100%;
}

.NewOnboardModal-NextButton {
    width: 105px;
    height: 36px;
    border-radius: 4px;
    outline: none;
    border: none;
    background-color: #005151;
    color: white;
    font-size: 14px;
    font-weight: 600;
    margin-left: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.NewOnboardModal-NextButton:hover {
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 25%);
    cursor: pointer;
}
