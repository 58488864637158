.RequestForm {
    display: flex;
    flex-direction: column;
    margin-right: 20px;
    margin-left: 20px;
}

.RequestForm-Title {
    display: flex;
    flex-direction: row;
    color: #202024;
    font-family: Montserrat;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 100%;
    letter-spacing: -0.14px;
    margin-top: 12px;
    margin-bottom: 12px;
}

.RequestForm-Message {
    display: flex;
    flex-direction: column;
    margin: 6px;
    border-top: 1px solid #454646;
    border-bottom: 1px solid #454646;
    font-size: 12px;
    font-weight: 500;
}

.RequestForm-TitleWrapper {
    display: flex;
    flex-direction: column;
    margin-top: 12px;
    margin-bottom: 12px;
    font-size: 14px;
    font-weight: 500;
}

.RequestForm-SubTitle {
    display: flex;
    flex-direction: row;
    flex-grow: 1;
    margin-top: 28px;
    width: 100%;
    align-items: center;
}

.RequestForm-SubTitle-Addition {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    flex-grow: 1;
    flex-wrap: wrap;
}

.RequestForm-Col {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 10px 10px;
}

.RequestForm-Col-title {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 12px;
    font-weight: 500;
    color: #757775;
}

.RequestForm-Col-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 12px;
    font-weight: 400;
    color: #757775;
}

.RequestForm-Spacer {
    display: flex;
    flex-direction: row;
    flex-grow: 1;
}

.RequestForm-Name {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    color: #202024;
    font-size: 14px;
    font-family: Montserrat;
    font-style: normal;
    font-weight: 500;
    letter-spacing: -0.14px;
    margin-right: 63px;
}

.RequestForm-Topic {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    color: #202024;
    text-align: center;
    font-size: 14px;
    font-family: Montserrat;
    font-style: normal;
    font-weight: 500;
    letter-spacing: -0.14px;
    margin-right: 58px;
}

.RequestForm-Status {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    color: #202024;
    text-align: center;
    font-size: 14px;
    font-family: Montserrat;
    font-style: normal;
    font-weight: 500;
    letter-spacing: -0.14px;
}

.RequestForm-Time {
    display: flex;
    flex-direction: row;
    justify-content: left;
    align-items: center;
    color: #202024;
    text-align: right;
    font-size: 12px;
    font-family: Montserrat;
    font-style: normal;
    font-weight: 500;
    letter-spacing: -0.12px;
}

.RequestForm-AdditionalInfo {
    display: flex;
    flex-direction: row;
    justify-content: left;
    align-items: center;
    color: #202024;
    text-align: left;
    font-size: 12px;
    font-family: Montserrat;
    font-style: normal;
    font-weight: 500;
    letter-spacing: -0.12px;
    max-width: 200px;
    overflow-wrap: break-word;
}
