.Overview {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    height: 100vh;
    min-height: 100vh;
    margin-left: 6vw;
    margin-right: 6vw;
    text-align: left;
}

.Overview-Title {
    color: #005151;
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: Montserrat;
    font-size: 60px;
    font-style: normal;
    font-weight: 600;
    line-height: 1.0; /* 60px */
    letter-spacing: -3.6px;
    margin-bottom: 20px;
}

.Overview-Subtitle {
    display: flex;
    font-size: 25px;
    text-align: left;
    max-width: 689.06px;
    color: #0E1019;
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: Montserrat;
    font-style: normal;
    font-weight: 600;
    line-height: 100%; /* 25px */
    letter-spacing: -1.5px;
}

.Overview-Features {
    color: #005151;
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: Montserrat;
    font-size: 30px;
    font-style: normal;
    font-weight: 600;
    line-height: 1.4;
    letter-spacing: -2.4px;
    margin: 0;  /* Removes margin from the ul */
}

.Overview-Features li {
    margin: 0 0 0 0;  /* Removes margin from the li, adds bottom margin */
}

.Overview-Features li h3 {
    margin: 0; /* Removes margin from the h3 */
    font-size: inherit; /* Inherits font size from parent ul */
    font-weight: inherit; /* Inherits font weight from parent ul */
    display: inline; /* Allows h3 to be inline with bullets */
}


@media (max-width: 1000px) {
    .Overview-Title{
        font-size: 35px;
        margin-bottom: 20px;
    }
    .Overview-Subtitle{
        font-size: 20px;
    }
    .Overview-Features{
        font-size: 25px;
    }
}
