
.Investors{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
    min-height: 100vh;
    margin-left: 6vw;
    margin-right: 6vw;
}

.InvestorsWrapper{
    display: flex;
    flex-direction: row;
    gap: 40px;
}

.InvestorTitle{
    color: #677389;
    font-size: 31px;
    font-weight: 600;
    text-align: center;
    margin-bottom: 44px;
}

.InvestorLogo{
    width: 300px;
}

.InvestorLogo:hover{
    cursor: pointer;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 25%);
}

@media (max-width: 1000px) {
    .InvestorLogo{
        width: 200px;
    }
    .InvestorsWrapper{
        flex-direction: column;
        gap: 20px;
    }
}
