
.SiteTitle {
    display: flex;
    flex-direction: row;
    min-height: 80vh;
    margin-bottom: 100px;
    align-items: center;
    margin-left: 6vw;
    margin-right: 6vw;
}

.SiteTitle-LeftWrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
    width: 100%;
    flex-grow: 1;
}

.SiteTitle-RightWrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
    flex-shrink: 1;
}

.SiteTitle-MainTitle {
    display: flex;
    flex-direction: column;
    color: #005151;
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: Montserrat;
    font-size: 60px;
    font-style: normal;
    font-weight: 600;
    line-height: 100%;
    letter-spacing: -3.6px;
    margin-bottom: 40px;
}

.SiteTitle-SubTitle {
    display: flex;
    font-size: 25px;
    text-align: left;
    max-width: 689.06px;
    color: #0E1019;
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: Montserrat;
    font-style: normal;
    font-weight: 600;
    line-height: 100%; /* 25px */
    letter-spacing: -1.5px;
    margin-bottom: 40px;
}

.SiteTitle-BookDiscoveryCallButton {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    font-weight: 500;
    width: 248.883px;
    height: 53px;
    flex-shrink: 0;
    border-radius: 77px;
    background: #377C6F;

    /* Text CSS */
    color: #FFF;
    text-align: center;
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: Montserrat;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 100%; /* 20px */
    letter-spacing: -1.2px;
}

.SiteTitle-BookDiscoveryCallButton:hover {
    cursor: pointer;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 25%);
}

.SiteTitle-BottomRow{
    display: flex;
    flex-direction: row;
    align-items: left;
}

.SiteTitle-HIPAACompliant{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    height: 100%;
    margin-left: 16.59px;
}

.SiteTitle-HIPAACompliant-Icon{
    width: 23.424px;
    height: 24px;
    flex-shrink: 0;
}

.SiteTitle-HIPAACompliant-Text{
    color: #0E1019;
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: Montserrat;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 100%; /* 20px */
    letter-spacing: -1.2px;
}

@media (max-width: 1000px) {
    .SiteTitle {
        flex-direction: column;
    }
    .SiteTitle-MainTitle{
        font-size: 35px;
        margin-bottom: 20px;
        letter-spacing: 0px;
    }
    .SiteTitle-SubTitle{
        font-size: 15px;
        margin-bottom: 20px;
        letter-spacing: 0px;
    }
    .SiteTitle-BookDiscoveryCallButton{
        width: 200px;
        height: 40px;
        font-size: 15px;
    }

    .SiteTitle-HIPAACompliant-Text{
        font-size: 15px;
    }
}
