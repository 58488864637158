/* Basic styling for the search bar container */
.search-container {
    position: relative;
    height: 48px;
    display: flex;
    flex-shrink: 0;
    flex-grow: 1;
    align-items: left;
    justify-content: space-between;
    /* padding: 8px; */
    /* border: 1px solid #ccc; */
    /* width: 100%; */
    outline: none;
    border-radius: 24px;
    background: rgba(164, 200, 200, 0.23);
}

/* Styling for the search input */
.search-input {
    padding: 8px;
    border: none;
    width: 100%;
    outline: none;
    background: none;
    padding-left: 16px;
}

/* Styling for the search icon */
.search-icon {
    margin: 8px;
    margin-right: 8px;
    margin-left: 8px;
    position: relative;
    display: flex;
    height: 32px;
    width: 40px;
    border-radius: 64px;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.search-icon:hover {
    background: rgba(164, 200, 200, 0.46);
}
