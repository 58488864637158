body {
    background-color: #F7F8FB;
    font-family: 'Montserrat';
}

.MVPMainPage{
    display: flex;
    flex-direction: column;
    justify-content: left;
    background-color: #F7F8FB;
}

.MVPHeader {
    display: flex;
    flex-direction: row;
    justify-content: left;
    height: 64px;
    max-width: 100vw;
}

.MVPPageBody{
    display: flex;
    flex-direction: row;
    justify-content: left;
    height: 100%;
    max-width: 100vw;
}
