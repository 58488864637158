.RequestModal {
    z-index: 1;
    width: 90%; /* Use percentage to make it responsive */
    max-width: 770px; /* Set a maximum width */
    height: 90%; /* Use percentage to make it responsive */
    max-height: 722.28px; /* Set a maximum height */
    overflow-y: auto;
    background-color: #FFFFFF;
    position: fixed;
    top: 50%; /* Center vertically */
    left: 50%; /* Center horizontally */
    transform: translate(-50%, -50%); /* Adjust position to center */
    box-shadow: 0px 4px 23px 8px rgba(0, 0, 0, 25%);
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    display: flex;
    flex-direction: column;
}

.RequestModal-Header {
    display: flex;
    flex-direction: row;
    justify-content: left;
    align-items: center;
    height: 40px;
    width: 100%;
    background-color: #F4F6FB;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
}

.RequestModal-HeaderTitle {
    font-family: 'Montserrat';
    font-size: 13px;
    font-weight: 600;
    display: flex;
    align-items: center;
    width: 100%;
    margin-left: 17px;
    color: #0B1E45;
    height: 40px;
}

.RequestModal-form {
    display: flex;
    flex-direction: column;
    margin-left: 17px;
    margin-right: 17px;
    margin-top: 17px;
    justify-content: space-between;
}

.RequestModal-row {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 17px;
}

.LineIcon {
    margin-right: 11px;
    max-height: 9px;
}

.ExpandIcon {
    margin-right: 10px;
    height: 10px;
}

.CancelIcon {
    margin-right: 15px;
    height: 12px;
}

.CancelIcon:hover {
    cursor: pointer;
}

.nakedButton {
    outline: none;
    border: none;
    background-color: #F4F6FB;
}

.RequestModalForm {
    display: flex;
    flex-direction: column;
    height: 100%;
}



.FormBox {
    height: 39px;
    border: none;
    outline: none;
    background: none;
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-grow: 1;
    min-width: 137px;
}

.topbuffer {
    padding-top: 20px;
    padding-right: 20px;
}

.Form-Line-bold {
    display: flex;
    flex-direction: row;
    border: 1px solid #757775;
    border-left: none;
    border-right: none;
    border-top: none;
}

.Form-Box {
    display: flex;
    width: 100%;
}

.Form-Box-radio {
    display: flex;
    flex-direction: row;
    justify-content: left;
    align-items: center;
    width: 100%;
}

.Form-Line {
    display: flex;
    flex-direction: row;
    gap: 20px;
}

.FormRadioLabel {
    display: flex;
    justify-self: center;
    align-items: center;
    font-size: 13px;
    height: 39px;
    min-height: 39px;
    font-weight: 500;
    margin-left: 9px;
    margin-right: 37px;
    color: #757775;
}

.Sm {
    font-size: 10px;
    line-height: 100%;
}

.VerticalLine {
    margin-right: 16px;
}

.Message {
    display: flex;
    width: 100%;
    height: 100%;
    flex-grow: 2;
}

.Spacer {
    display: flex;
    height: 100%;
}

.MessageStatic {
    display: flex;
    width: max-content;
    word-wrap: break-word;
    border: none;
    outline: none;
    white-space: pre;
    font-size: 14px;
    font-weight: 400;
    font-family: 'Montserrat';
    overflow: visible;
    margin-top: 10px;
}

.MessageUneditable {
    display: flex;
    width: fit-content;
    max-height: 16px;
    font-size: 14px;
    font-weight: 600;
    font-family: 'Montserrat';
    margin-top: 10px;
    white-space: pre;
}

.MessageEditable {
    display: flex;
    justify-self: flex-start;
    width: 100%;
    height: 100%;
    border: none;
    outline: none;
    background: none;
    display: flex;
    flex-direction: row;
    margin-top: 10px;
    resize: none;
    overflow: hidden;
}

.CollapseButton {
    outline: none !important;
    border: none !important;
    background: none !important;
    padding-top: -10px;
    z-index: 2;
}

.Footer {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    height: fit-content;
    margin-bottom: 30px;
    margin-top: 24px;
    position: relative;
    bottom: 0px;
}

.RequestSubmitButton {
    width: 105px;
    height: 36px;
    border-radius: 18px;
    outline: none;
    border: none;
    background-color: #005151;
    color: white;
    font-size: 14px;
    font-weight: 600;
    margin-left: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.RequestSubmitButton:hover {
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 25%);
}

.RequestSubmitButton:hover {
    cursor: pointer;
}

.TrashIcon {
    margin-right: 33px;
    background-color: white;
    outline: none;
    border: none;
}

.TrashIcon:hover {
    cursor: pointer;
}

.RequestModal-Subtitle {
    align-items: left;
    width: 100%;
    /* color: #757775; */
    color: #000;
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: Montserrat;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 100%;
    letter-spacing: -0.14px;
    margin-bottom: 15px;
}
