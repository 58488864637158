body {
    background-color: white;
    font-family: 'Montserrat';
}

.Onboarding {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: 100vh;
}

.OnboardingFrame {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 888px;
    height: 647px;
    flex-shrink: 0;
    border-radius: 16px;
    border: 1px solid #DADCE0;
    background: #FFF;
}
