.FinalCallToAction {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
    min-height: 100vh;
    margin-left: 6vw;
    margin-right: 6vw;
}

.FinalCallToAction-Title {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: #005151;
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: Montserrat;
    font-size: 60px;
    font-style: normal;
    font-weight: 600;
    line-height: 100%;
    letter-spacing: -3.6px;
    margin-bottom: 40px;
    text-align: center;
}

.FinalCallToAction-BookDiscoveryCallButton {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    font-weight: 500;
    width: 248.883px;
    height: 53px;
    flex-shrink: 0;
    border-radius: 77px;
    background: #377C6F;

    /* Text CSS */
    color: #FFF;
    text-align: center;
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: Montserrat;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 100%; /* 20px */
    letter-spacing: -1.2px;
}

.FinalCallToAction-BookDiscoveryCallButton:hover {
    cursor: pointer;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 25%);
}

@media (max-width: 1000px) {
    .FinalCallToAction-Title{
        font-size: 35px;
    }

}
