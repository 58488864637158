.RequestHeader {
  display: flex;
  flex-direction: row;
  justify-content: left;
  align-items: center;
  width: 100%;
  height: 48px;
  font-size: 14px;
  font-weight: 500;
  border: 1px solid #e2e7ed;
  border-top: none;
  border-left: none;
  border-right: none;
  flex-shrink: 0;
}

.RequestHeader-LeftArrow {
  width: 11px;
  margin-left: 24px;
  margin-right: 43px;
}

.RequestHeader-LeftArrow:hover {
  cursor: pointer;
}

.RequestHeader-TrashIcon:hover {
  cursor: pointer;
}

.RequestHeader-Spacer {
  display: flex;
  flex-direction: row;
  flex-grow: 1;
}

.RequestHeader-PrintIcon {
  margin-right: 48px;
}

.RequestHeader-PrintIcon:hover {
  cursor: pointer;
}

/* .RequestHeaderName{
    min-width: 100px !important;
    max-width: 100px !important;
    margin-left: 34px;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.RequestHeaderTopic{
    min-width: 90px !important;
    height: 14px;
    margin-left: 60px;
}

.RequestHeaderStatus{
    min-width: 121px !important;
    height: 24px;
    margin-left: 60px;
    border-radius: 16px;
    background-color: #426B1F;
    color: #FFFFFF;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.RequestHeaderSubject{
    display: flex;
    flex-direction: row;
    width: 100%;
    margin-left: 51px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

.RequestHeaderTime{
    display: flex;
    justify-content: right;
    align-items: right;
    min-width:fit-content !important;
    color: #202024;
    margin-right: 24px;
    height: 14px;
    white-space: nowrap;
    overflow: hidden;
} */
