/* OnboardingInput */
.CustomFormInput-Group {
  display: flex;
  position: relative;
  margin-bottom: 20px;
  /* Adjust as needed */
  width: 100%;
  max-height: 55px;
}

.CustomFormInput-Label {
  position: absolute;
  top: -7px;
  /* Adjust as needed */
  left: 10px;
  /* Adjust as needed */
  color: #999;
  /* Adjust label color */
  pointer-events: none;
  transition: 0.2s ease-out all;
  background-color: #fff;
  /* Adjust label background color */
  padding: 0 5px;
  /* Adjust label padding */
  z-index: 2;
  font-size: 12px;
  /* Adjust label font size */
  font-family: Montserrat;
}

.CustomFormInput-Input {
  width: 100%;
  height: 35px;
  padding: 10px;
  border-radius: 4px;
  border: 1px solid #DADCE0;
  position: relative;
  z-index: 1;
}

.CustomFormInput-Input-Paragraph {
  width: 100%;
  height: 35px;
  padding: 10px;
  border-radius: 4px;
  border: 1px solid #DADCE0;
  position: relative;
  z-index: 1;
  resize: none; /*Prevents the user from resizing the textarea */
}

.CustomFormInput-Error {
  width: 100%;
  height: 35px;
  padding: 10px;
  border-radius: 4px;
  border: 1px solid rgb(255, 105, 105);
  position: relative;
  z-index: 1;
}

.CustomFormInput-Error-Paragraph {
  width: 100%;
  height: 35px;
  padding: 10px;
  border-radius: 4px;
  border: 1px solid #DADCE0;
  position: relative;
  z-index: 1;
  resize: none; /*Prevents the user from resizing the textarea */
}

.CustomFormInput-Input:focus {
  outline: none;
}

.CustomFormInput-Error:focus {
  outline: none;
}

/* .CustomFormInput-Input:focus + .CustomFormInput-Label, */
/* .CustomFormInput-Input:not(:placeholder-shown) + .CustomFormInput-Label { */
/* font-size: 12px; Adjust label font size */
/* font-family: Montserrat; */
/* transform: translate(-5px, -20px); Adjust label position */
/* box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); Add a shadow effect */
/* margin-bottom: -6px; Lift the input field to accommodate the label */
/* } */

/* .CustomFormInput-Error:focus + .CustomFormInput-Label, */
/* .CustomFormInput-Error:not(:placeholder-shown) + .CustomFormInput-Label { */
/* font-size: 12px; Adjust label font size */
/* font-family: Montserrat; */
/* transform: translate(-5px, -20px); Adjust label position */
/* box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); Add a shadow effect */
/* margin-bottom: -6px; Lift the input field to accommodate the label */

/* } */

/* InputDropdown */

.CustomFormDropdown-Group {
  display: flex;
  position: relative;
  margin-bottom: 20px;
  /* Adjust as needed */
  width: 100%;
  max-height: 55px;
  height: 55px;
  flex-grow: 1;
}

.CustomFormDropdown-Group-Shrink {
  display: flex;
  position: relative;
  margin-bottom: 20px;
  /* Adjust as needed */
  min-width: 250px;
  max-height: 55px;
  height: 55px;
  flex-grow: 1;
}

.CustomFormDropdown-Label {
  position: absolute;
  top: -7px;
  /* Adjust as needed */
  left: 10px;
  /* Adjust as needed */
  color: #999;
  /* Adjust label color */
  pointer-events: none;
  transition: 0.2s ease-out all;
  background-color: #fff;
  /* Adjust label background color */
  padding: 0 5px;
  /* Adjust label padding */
  z-index: 2;
  font-size: 12px;
  /* Adjust label font size */
  font-family: Montserrat;
}

.CustomFormDropdown-Input {
  width: 100%;
  height: 100%;
  padding: 10px;
  /* Adjust padding as needed */
  /* border: none; Remove the border */
  border-radius: 4px;
  border: 1px solid #DADCE0;
  /* border-bottom: 1px solid #999;  */
  position: relative;
  z-index: 1;
}

.CustomFormDropdown-Error {
  width: 100%;
  height: 100%;
  padding: 10px;
  /* Adjust padding as needed */
  /* border: none; Remove the border */
  border-radius: 4px;
  border: 1px solid rgb(255, 105, 105);
  /* border-bottom: 1px solid #999;  */
  position: relative;
  z-index: 1;
}

/* .DropdownInputLabel-Error{
    color: rgb(255, 105, 105);
} */

.CustomFormDropdown-Input:focus {
  outline: none;
}

.CustomFormDropdown-Error:focus {
  outline: none;
}

.CustomFormDropdown-Input:focus+.CustomFormDropdown-Label,
.CustomFormDropdown-Input:not(:placeholder-shown)+.CustomFormDropdown-Label {
  font-size: 12px;
  /* Adjust label font size */
  font-family: Montserrat;
  transform: translate(-5px, -20px);
  /* Adjust label position */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  /* Add a shadow effect */
  margin-bottom: -6px;
  /* Lift the input field to accommodate the label */
}

.CustomFormDropdown-Error:focus+.CustomFormDropdown-Label,
.CustomFormDropdown-Error:not(:placeholder-shown)+.CustomFormDropdown-Label {
  font-size: 12px;
  /* Adjust label font size */
  font-family: Montserrat;
  transform: translate(-5px, -20px);
  /* Adjust label position */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  /* Add a shadow effect */
  margin-bottom: -6px;
  /* Lift the input field to accommodate the label */
  /* color: rgb(255, 105, 105); */
}
